import qs from "qs";

const state = () => ({
  data: null,
});

const mutations = {
  setData(state, data) {
    state.data = data;
  },
};

const actions = {
  async loadGiftList({ commit }) {
    const response = await this.$api.get("giftList");
    commit("setData", response?.details || []);
  },

  async loadGeoAdressDelivery() {
    const calculate = 1

    const params = { calculate }

    const response = await this.$api.get("geoAdressDelivery", { params });
   
    if(response) {
      return response
    }
  },

  async addGift({ dispatch }, id) {
    const response = await this.$api.post(
      "addGiftToCart",
      qs.stringify({ item_id: id })
    );
    // await dispatch("loadGiftList");
    await dispatch("checkout/loadCart", null, { root: true });
    return response;
  },

  async removeGift({ dispatch }, id) {
    const response = await this.$api.post(
      "demolishGiftFromCart",
      qs.stringify({ item_id: id })
    );
    // await dispatch("loadGiftList");
    await dispatch("checkout/loadCart", null, { root: true });
    return response;
  },
};

export default {
  state,
  mutations,
  actions,
};
