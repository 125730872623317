<template>
  <v-dialog v-model="isOrderedDialog" :persistent="false" max-width="400">
    <v-card elevation="2">
      <div role="contentinfo">
        <v-img
          :src="
            orderedShopSettings.img || require('@/assets/images/no-photo.svg')
          "
        ></v-img>
      </div>
      <div class="icon-window-close">
        <v-icon @click.capture="closeDialog()">
          {{ icons.mdiWindowClose }}
        </v-icon>
      </div>
      <v-card-text
        class="text-center dialog-card-text dark--text mt-4"
        v-html="orderedShopSettings.msg"
        v-if="orderedShopSettings && orderedShopSettings.msg"
      >
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { makeHydrationDialog } from '@/mixins/dialogMixin'
export default {
  name: 'isOrderedDialog',
  data: () => ({
    isOrderedDialog: false,
  }),
  mixins: [makeHydrationDialog()],
  methods: {
    closeDialog() {
      if (this.isOrderedDialog) this.$set(this, this.$options.name, false)
    },
  },
  mounted() {
    if (this.orderedShopSettings?.enabled)
      this.$set(this, this.$options.name, true)
  },
}
</script>

<style></style>
