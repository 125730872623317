const state = () => ({
  active: false,
  scrollY: null,
})

const mutations = {
  hide(state) {
    state.active = false
    window.scrollTo(0, state.scrollY)
  },

  show(state) {
    state.active = true
    state.scrollY = window.pageYOffset
    window.scrollTo(0, 0)
  },
}

const actions = {
  toggle({ state, commit }) {
    state.active ? commit('hide') : commit('show')

    if (!state.active) {
      if (!sessionStorage.getItem('menu_hide')) {
        sessionStorage.setItem('menu_hide', true)
      }
    }
  },

  hide({ commit }) {
    commit('hide')

    setTimeout(() => {
      if (
        !sessionStorage.getItem('menu_hide') &&
        (this.$router?.currentRoute?.name === 'Catalog' ||
          this.$router?.currentRoute?.name === 'Home')
      ) {
        sessionStorage.setItem('menu_hide', true)
      }
    }, 100)
  },
}

export default {
  state,
  mutations,
  actions,
}
