/* globals grecaptcha */

import store from '@/store/index'

const RecaptchaPlugin = {
  install(Vue) {
    if (store.getters['app/recaptchaEnabled'] === false) {
      return
    }

    const RECAPTCHA_SITE_KEY = window.RECAPTCHA_SITE_KEY

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    document.head.appendChild(script)

    Vue.prototype.$recaptcha = () =>
      new Promise((resolve) =>
        grecaptcha.ready(() =>
          grecaptcha
            .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
            .then(resolve)
        )
      )
  },
}

export default RecaptchaPlugin
