<template>
  <div :class="{ jw74: isJw74 }">
    <component :is="layout" />
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/Empty'
import MainLayout from '@/layouts/Main'
import ErrorLayout from '@/layouts/Error'

export default {
  name: 'App',

  components: {
    EmptyLayout,
    MainLayout,
    ErrorLayout,
  },

  computed: {
    layout() {
      if (this.errors) {
        return 'error' + '-layout'
      }

      return (this.$route.meta.layout || 'main') + '-layout'
    },

    errors() {
      return this.$store.getters['app/errors']
    },

    isJw74() {
      return (
        localStorage.getItem('merchantKey') ===
        '776151e60de0b99f011a13fe4cb84d17'
      )
    },
  },
}
</script>
