var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isBottomPresentPosition && _vm.display && _vm.scrollDisplay)?_c('div',[_c('div',{staticClass:"present present-base-mobile",class:_vm.freeDelivery <= _vm.animatedNumber ? 'transparency' : ''},[_c('div',{staticClass:"present-wrap",class:_vm.minPrice == _vm.cartPrice
          ? 'present-black'
          : _vm.listPresent[0].presents_summ <= _vm.cartPrice
          ? 'present-ligth'
          : 'present-black'},[_c('div',{staticClass:"present-icon opacity-0",style:(_vm.minPrice <= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(0)]),_c('div',{staticClass:"present-icon opacity-0",style:(_vm.freeDelivery < _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(1)]),_c('div',{staticClass:"present-txt"}),_c('div',{staticClass:"present-icon",style:(_vm.minPrice >= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_c('img',{staticClass:"opacity-0",attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})]),_c('div',{staticClass:"present-icon",style:(_vm.freeDelivery >= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_c('img',{staticClass:"opacity-0",attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})])])]),_c('div',{staticClass:"present transparency present-base-mobile"},[_c('div',{staticClass:"present-wrap transparency"},[_c('div',{staticClass:"present-icon opacity-0",style:(_vm.minPrice <= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(2)]),_c('div',{staticClass:"present-icon opacity-0",style:(_vm.freeDelivery < _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(3)]),_c('div',{staticClass:"present-txt transparency"},[_c('span',{staticClass:"present-bg",style:(_vm.styleMinPrice)},[_c('div',{staticClass:"present-icon__bg"})])]),_c('div',{staticClass:"present-icon transparency",style:(_vm.minPrice >= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_c('img',{staticClass:"opacity-0",attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})]),_c('div',{staticClass:"present-icon transparency",style:(_vm.freeDelivery >= _vm.animatedNumber
            ? { display: 'block' }
            : { display: 'none' })},[_c('img',{staticClass:"opacity-0",attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})])])]),_c('div',{staticClass:"present transparency present-base-mobile"},[_c('div',{staticClass:"present-wrap transparency"},[_c('div',{staticClass:"present-icon",style:(_vm.minPrice <= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(4)]),_c('div',{staticClass:"present-icon",style:(_vm.freeDelivery < _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_vm._m(5)]),_c('div',{staticClass:"present-txt transparency",on:{"click":_vm.toGift}},[_c('div',{staticClass:"present-icon__bg transparency"},[(_vm.cartPrice === 0)?_c('img',{attrs:{"src":require("@/assets/images/gift/begin_dark_gray.svg"),"alt":"begin_dark_gray"}}):(_vm.minPrice > _vm.cartPrice)?_c('img',{attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}}):(_vm.freeDelivery >= _vm.cartPrice)?_c('img',{attrs:{"src":require("@/assets/images/gift/delivery_white.svg"),"alt":"begin_white"}}):_vm._e()]),(_vm.freeDelivery >= _vm.cartPrice)?_c('span',{staticClass:"present-promo present-promo-mobile"},[(_vm.minPrice >= _vm.cartPrice)?_c('span',[_vm._v(" Минимальная сумма заказа "+_vm._s(_vm.minPrice)+" "+_vm._s(_vm.$store.getters["app/currency"])+" ")]):(_vm.freeDelivery >= _vm.cartPrice)?_c('span',[_vm._v(" Минимальная сумма бесплатной доставки "+_vm._s(_vm.freeDelivery)+" "+_vm._s(_vm.$store.getters["app/currency"])+" ")]):_vm._e()]):_c('div',{staticClass:"present-txt-list present-txt-w present-txt-mobile"},[_c('span',{staticClass:"present-icon__bg present-icon__bg-c present-txt-item present-icon__bg--color relative z-50",attrs:{"to":"/gift"},on:{"click":_vm.toGift}},[_c('img',{attrs:{"src":require("@/assets/images/gift/present_white.svg"),"alt":"present_white"}})]),_vm._l((_vm.listPresent),function(present,i){return _c('span',{key:i,staticClass:"present-txt-item"},[(
                present.presents_summ <= _vm.cartPrice &&
                _vm.listPresent[i + 1] &&
                _vm.cartPrice < _vm.listPresent[i + 1].presents_summ
              )?_c('span',{staticClass:"border-b border-dotted cursor-pointer flex items-center relative z-50",attrs:{"to":"/gift"},on:{"click":_vm.toGift}},[_vm._v(" выбрать ")]):(
                _vm.listPresent[_vm.listPresent.length - 1].presents_summ <=
                  _vm.cartPrice &&
                present.presents_summ ===
                  _vm.listPresent[_vm.listPresent.length - 1].presents_summ
              )?_c('span',{staticClass:"border-b border-dotted cursor-pointer flex items-center relative z-50",attrs:{"to":"/gift"},on:{"click":_vm.toGift}},[_vm._v(" выбрать ")]):(present.presents_summ < _vm.cartPrice)?_c('span',{staticClass:"opacity-50 relative z-50"},[_vm._v(_vm._s(present.presents_summ)+" "+_vm._s(_vm.$store.getters["app/currency"]))]):_c('span',{staticClass:"relative z-50"},[_vm._v(_vm._s(present.presents_summ)+" "+_vm._s(_vm.$store.getters["app/currency"]))])])})],2)]),_c('div',{staticClass:"present-icon transparency",style:(_vm.minPrice >= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_c('img',{attrs:{"src":require("@/assets/images/gift/delivery_gray.svg"),"alt":"delivery_gray"}})]),_c('div',{staticClass:"present-icon transparency",style:(_vm.freeDelivery >= _vm.animatedNumber
            ? { display: 'flex' }
            : { display: 'none' })},[_c('img',{attrs:{"src":require("@/assets/images/gift/present_gray.svg"),"alt":"present_gray"}})])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/delivery_white.svg"),"alt":"delivery_white"}}),_c('img',{staticClass:"present-icon__wrap-true",staticStyle:{"width":"8px"},attrs:{"src":require("@/assets/images/gift/true.svg"),"alt":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/delivery_white.svg"),"alt":"delivery_white"}}),_c('img',{staticClass:"present-icon__wrap-true",staticStyle:{"width":"8px"},attrs:{"src":require("@/assets/images/gift/true.svg"),"alt":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/begin_white.svg"),"alt":"begin_white"}}),_c('img',{staticClass:"present-icon__wrap-true",staticStyle:{"width":"8px"},attrs:{"src":require("@/assets/images/gift/true.svg"),"alt":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"present-icon__wrap"},[_c('img',{attrs:{"src":require("@/assets/images/gift/delivery_white.svg"),"alt":"delivery_white"}}),_c('img',{staticClass:"present-icon__wrap-true",staticStyle:{"width":"8px"},attrs:{"src":require("@/assets/images/gift/true.svg"),"alt":"true"}})])
}]

export { render, staticRenderFns }