import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

import api from '@/plugins/api'
Vuex.Store.prototype.$api = api

import router from '@/router/index'
Vuex.Store.prototype.$router = router

Vue.use(Vuex)

const actions = {
  async appInit({ dispatch }) {
    await Promise.all([
      dispatch('app/loadSettings'),
      dispatch('app/getMerchantInfo'),
      dispatch('app/loadPages'),
      dispatch('catalog/loadSections'),
      dispatch('user/checkToken'),
      dispatch('checkout/loadCart'),
      dispatch('checkout/loadOrderTypes'),
      dispatch('checkout/loadPickupPoints'),
      dispatch('aggregator/loadSettings'),
    ])
  },
}

export default new Vuex.Store({
  actions,
  modules,
})
