<template>
  <div v-if="isGift">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__backInRight"
      leave-active-class="animate__animated animate__backOutRight"
    >
      <div class="gift-button" @click="toGift()">
        <gift-circle />

        <svg
          class="progress-bar"
          version="1.1"
          width="54"
          height="54"
          viewBox="0 0 82 82"
        >
          <path
            ref="circleProgress"
            fill="transparent"
            stroke="#c7cacf"
            stroke-width="16"
            :stroke-dashoffset="strokeDashoffset"
            :stroke-dasharray="strokeDasharray"
            d="M 40.43279679078829 8.504949907417284 A 32.5 32.5 0 1 0 41 8.5"
          />
        </svg>

        <icon-gift-active v-if="level" class="icon-gift-active" />
        <icon-gift v-else class="icon-gift" />

        <div class="gift-level">{{ level }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import GiftCircle from "@/components/icons/GiftCircle";
import IconGift from "@/components/icons/IconGift";
import IconGiftActive from "@/components/icons/IconGiftActive";
import { mapState } from "vuex";

export default {
  components: {
    GiftCircle,
    IconGift,
    IconGiftActive,
  },

  computed: {
    circumference() {
      return Math.PI * 64;
    },

    isGift() {
      return this.$store.getters["app/isGift"];
    },

    strokeDashoffset() {
      return -(this.circumference * this.percent) / 100;
    },

    strokeDasharray() {
      return `${this.circumference} ${this.circumference}`;
    },

    percent() {
      return this.data?.context?.delta_level || 0;
    },

    level() {
      return this.data?.context?.current_level || 0;
    },

    ...mapState("gift", ["data"]),
  },

  methods: {
    toGift() {
      this.$store.commit("menu/hide");
      this.$router.push({ name: "Gift" });
    },
  },
};
</script>

<style lang="scss" scoped>
.gift-button {
  position: fixed;
  bottom: 100px;
  margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
}

.gift-level {
  position: absolute;
  top: -3px;
  height: 18px;
  width: 18px;
  line-height: 16px;
  border: 1px solid #fff;
  background: #f59709;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}

.progress-bar {
  position: absolute;

  path {
    transform-origin: center;
    transform: rotateY(180deg);
    transition: 2s ease;
  }
}

.icon-gift,
.icon-gift-active {
  position: absolute;
}

.icon-gift-active {
  margin-top: -2px;
  margin-left: -2px;
}
</style>
