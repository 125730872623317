const state = () => ({
  active: false,
  text: '',
})

const mutations = {
  update(state, obj) {
    if (state.active && obj.active) {
      state.active = false
      setTimeout(update, 200)
    } else update()

    function update() {
      for (const key in obj) {
        const value = obj[key]
        state[key] = value
      }
    }
  },

  clear(state) {
    state.active = false
    state.text = ''
  },
}

export default {
  state,
  mutations,
}
