import axios from 'axios'
import store from '@/store/index'

const { baseURL, merchantKey } = window

const api = axios.create({ baseURL, timeout: 10000 })

// const merchant_keys = localStorage.getItem("merchantKey") || merchantKey;

const time = new Date().getTime()

api.interceptors.request.use((config) => {
  const { device } = window

  config.params = {
    merchant_keys: localStorage.getItem('merchantKey') || merchantKey,
    device_id:
      store.state.app.fcmToken ||
      device?.uuid ||
      device?.model ||
      time ||
      'no_device',
    device_platform: localStorage.getItem('platform') || 'no_platform',
    lang: 'ru',
    json: true,
    push_id: store.state.app.playerId,
    ...config.params,
  }

  if (
    store.getters['aggregator/isAggregatorChild'] &&
    store.state.aggregator.key &&
    [
      'login',
      'customerRegister',
      'requestForgotPass',
      'sendconfirmationcode',
      'loginbyphoneandcode',
    ].includes(config.url)
  ) {
    config.params.merchant_keys = store.state.aggregator.key
  }

  const Authtoken = store.state.user?.token
  if (Authtoken) config.params.token = Authtoken

  const orderType = store.state.checkout.orderType
  const pickupPointId = store.state.checkout.fields?.pickupPoint
  const deliveryZoneName = store.state.checkout.fields?.zone

  if (orderType === 'pickup' && pickupPointId) {
    config.params.point_delivery = pickupPointId
  }

  if (orderType === 'delivery' && deliveryZoneName) {
    config.params.zone = deliveryZoneName
  }

  // alert(config.params.device_id);

  return config
})

api.interceptors.response.use(
  function (response) {
    if (response.config.url === 'geoAdressDelivery') {
      store.commit('checkout/setField', {
        name: 'zone',
        value: response.data.details?.zone || '',
      })
    }
    return response.data
    // return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default api
