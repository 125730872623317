const state = () => ({
  list: [],
  details: {},
});

const getters = {
  detail: (state) => (id) => state.details[id],
};

const mutations = {
  setList(state, sales) {
    state.list = sales;
  },
  setDetail(state, sale) {
    this._vm.$set(state.details, sale.id, sale);
  },
};

const actions = {
  async loadList({ state, commit }) {
    if (state.list && state.list.length) return;
    const response = await this.$api.get("loadSales");
    const sales = (response && response.details && response.details.data) || [];
    commit("setList", sales);
  },
  async loadDetail({ state, commit }, id) {
    if (state.details[id]) return;
    const response = await this.$api.get("getDetailSales", { params: { id } });
    const sale = response && response.details && response.details.data;
    if (sale && sale.id) {
      commit("setDetail", sale);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
