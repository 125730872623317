const state = () => ({
  token: null,
  profile: null,
  addressBook: null,
  addressDetail: null,
  history: null,
  historyDetail: null,
  bonuses: {
    details: {},
    up: {},
    down: {},
  },
  orderNotifications: [],
  actionNotifications: [],
})

const getters = {
  isAuthorized(state) {
    return !!state.token && !!state.profile
  },

  phone: ({ profile }) => {
    const userPhone = profile?.contact_phone || null
    if (!userPhone) return null
    return userPhone?.substring(1) || null
  },

  token(state) {
    return state.token
  },

  orderNotifications: (state) => state.orderNotifications,
  actionNotifications: (state) => state.actionNotifications,
}

const mutations = {
  setToken(state, token) {
    state.token = token
    localStorage.setItem('token', token)
  },

  setProfile(state, profile) {
    state.profile = profile
  },

  logout(state) {
    localStorage.removeItem('token')

    state.token = null
    state.profile = null
    state.addressBook = []
    state.history = null

    if (this.$router.currentRoute.name != 'Welcome') {
      this.$router.push({ name: 'Welcome' })
    }
  },

  setAddressBook(state, value) {
    state.addressBook = value
  },

  setAddressDetail(state, value) {
    state.addressDetail = value
  },

  setBonuses(state, value) {
    state.bonuses.details = value || {}
  },

  setBonusesUp(state, value) {
    state.bonuses.up = value || {}
  },

  setBonusesDown(state, value) {
    state.bonuses.down = value || {}
  },

  setHistory(state, value) {
    state.history = value
  },

  setHistoryDetail(state, value) {
    state.historyDetail = value
  },

  setOrderNotifications(state, value) {
    state.orderNotifications = value
  },

  setActionNotifications(state, value) {
    state.actionNotifications = value
  },
}

const actions = {
  async login({ dispatch, commit }, params) {
    commit('actionButton/update', { loading: true }, { root: true })

    const response = await this.$api.get('login', { params })

    if (response.code != 1) {
      if (response.msg) {
        commit(
          'snackbar/update',
          {
            active: true,
            text: response.msg,
          },
          { root: true }
        )
      }
    } else {
      const token = response.details.token
      const result = dispatch('checkToken', token)
      if (result) {
        this.$router.push({ name: 'Home' })
      }
    }

    commit('actionButton/update', { loading: false }, { root: true })
    return response
  },

  async registration({ commit, dispatch, rootGetters }, params) {
    commit('actionButton/update', { loading: true }, { root: true })

    const response = await this.$api.get('customerRegister', {
      params,
      headers: {
        ...(rootGetters['app/recaptchaEnabled'] && {
          authorization: await this._vm.$recaptcha(),
        }),
      },
    })

    if (response.code != 1) {
      if (response.msg) {
        commit(
          'snackbar/update',
          { active: true, text: response.msg },
          { root: true }
        )
      }
    } else {
      const token = response.details.token
      const result = dispatch('checkToken', token)
      if (result) {
        this.$router.push({ name: 'Home' })
      }
    }

    commit('actionButton/update', { loading: false }, { root: true })
    return response
  },

  async loadProfile({ commit }) {
    const response = await this.$api.get('getUserProfile')
    if (response.code == 1 && response?.details?.data) {
      commit('setProfile', response.details.data)
      return true
    }
    commit('logout')
    return false
  },

  async checkToken({ commit, dispatch }, value) {
    const token = value || localStorage.getItem('token')
    if (!token) return false
    commit('setToken', token)
    return await dispatch('loadProfile')
  },

  async loadAddressBook({ commit }) {
    const response = await this.$api.get('getAddressBookList')
    commit('setAddressBook', response?.details?.data || [])
  },

  async loadAddress({ commit }, id) {
    const response = await this.$api.get('getAddressBook', { params: { id } })
    commit('setAddressDetail', response?.details)
  },

  async saveAddress(store, params) {
    return await this.$api.get('saveAddressBook', { params })
  },

  async deleteAddress(store, id) {
    return await this.$api.get('deleteAddressBook', { params: { id } })
  },

  async loadBonuses({ commit }) {
    const response = await this.$api.get('pointsSummary')
    commit('setBonuses', response?.details)
  },

  async loadBonusesUp({ commit }) {
    const response = await this.$api.get('pointsGetEarn')
    commit('setBonusesUp', response?.details?.data)
  },

  async loadBonusesDown({ commit }) {
    const response = await this.$api.get('pointsExpenses')
    commit('setBonusesDown', response?.details?.data)
  },

  async loadHistory({ commit }) {
    const response = await this.$api.get('getOrders')
    commit('setHistory', response?.details?.data)
  },

  async loadHistoryDetail({ commit }, id) {
    const response = await this.$api.get('getOrderDetails', { params: { id } })
    commit('setHistoryDetail', response?.details)
  },

  async reOrder({ commit }, id) {
    const response = await this.$api.get('reOrder', { params: { id } })
    if (response.code == 1) {
      this.$router.push({ name: 'Cart' })
    } else {
      const text = 'Не удалось повторить заказ'
      commit('snackbar/update', { active: true, text }, { root: true })
    }
  },

  async saveProfile(store, params) {
    return await this.$api.get('saveProfile', { params })
  },

  async changePassword(store, params) {
    return await this.$api.get('saveChangePassword', { params })
  },

  async requestForgotPass(store, login) {
    return await this.$api.get('requestForgotPass', {
      params: { user_email: login },
      headers: {
        ...(store.rootGetters['app/recaptchaEnabled'] && {
          authorization: await this._vm.$recaptcha(),
        }),
      },
    })
  },

  async addReview(store, { orderID, review, rating }) {
    const response = await this.$api.get('addReview', {
      params: {
        review_order_id: orderID,
        rating,
        review,
      },
    })
    if (response.code !== 1) {
      throw new Error(response.msg || 'addReview error')
    }
    return response
  },

  async loadNotifications({ commit }) {
    const orderNotifications = await this.$api.get('loadNotification', {
      params: { push_data: 'order' },
    })
    const actionNotifications = await this.$api.get('loadNotification', {
      params: { push_data: 'action' },
    })
    commit('setOrderNotifications', orderNotifications.details?.data)
    commit('setActionNotifications', actionNotifications.details?.data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
