import Vue from "vue";
import VueRouter from "vue-router";
import personalRoutes from "@/router/personal";
import catalogRoutes from "@/router/catalog";

Vue.use(VueRouter);

const routes = [
  {
    path: "/gift",
    name: "Gift",
    component: () => import("@/views/Gift.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("@/views/Welcome.vue"),
    meta: {
      // layout: "empty",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/Registration.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/forgot",
    name: "ForgotPass",
    component: () => import("@/views/ForgotPass.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("@/views/Cart.vue"),
  },
  {
    path: "/cart/checkout",
    name: "Checkout",
    component: () => import("@/views/Checkout.vue"),
  },
  {
    path: "/sales",
    name: "Sales",
    component: () => import("@/views/Sales.vue"),
  },
  {
    path: "/sales/:id",
    name: "Sale",
    component: () => import("@/views/Sale.vue"),
    meta: {
      backButton: true,
    },
  },
  {
    path: "/page/:id",
    name: "CustomPage",
    component: () => import("@/views/CustomPage.vue"),
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: () => import("@/views/PaymentSuccess.vue"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("@/views/Notifications/Index.vue"),
  },
  {
    path: "/notifications/detail",
    name: "NotificationsDetail",
    component: () => import("@/views/Notifications/Detail.vue"),
  },
  {
    path: "/rules",
    name: "Rules",
    component: () => import("@/views/Rules.vue"),
  },

  {
    path: "/order",
    name: "Order",
    component: () => import("@/views/Order.vue"),
  },
  
  /* {
    path: "/test",
    name: "Test",
    component: () => import("@/views/Test.vue"),
  }, */

  ...catalogRoutes,
  ...personalRoutes,
];

const router = new VueRouter({
  routes,
  //mode: "history",
  scrollBehavior(to, from, savedPosition) {
    const names = ["Section", "Sales", "Catalog", "Home"];

    if (names.includes(to.name) && savedPosition) {
      // console.log(names.includes(to.name));
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
