<template>
  <div class="footer-inner grow">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInRight animate__faster"
      leave-active-class="animate__animated animate__slideOutLeft animate__faster"
    >
      <div
        v-if="actionButton.active && actionButton.action"
        key="actionBlock"
        class="d-flex align-center grow justify-space-around transition-block"
      >
        <v-btn
          class="footer-button footer-button--back"
          color="secondary"
          height="80"
          width="80"
          tile
          icon
          x-large
          @click="$router.go(-1)"
          key="back"
        >
          <!-- <v-icon x-large>{{ icons.mdiArrowLeft }}</v-icon> -->
          <!-- <v-icon x-large>{{ icons.mdiArrowLeftThick }}</v-icon> -->
          <IconBackArrow />
          <span class="footer-button__label dark--text">Назад</span>
        </v-btn>
        <div class="action-btn-wrapper grow d-flex">
          <action-button />
        </div>
      </div>

      <div
        v-else
        key="defaultBlock"
        class="d-flex align-center grow justify-space-around transition-block"
      >
        <template v-if="$route.meta.backButton">
          <v-btn
            class="footer-button grow footer-button--back"
            color="secondary"
            height="80"
            tile
            icon
            x-large
            @click="$router.go(-1)"
            key="back"
          >
            <!-- <v-icon x-large>{{ icons.mdiArrowLeftThick }}</v-icon> -->
            <IconBackArrow />
            <span class="footer-button__label dark--text">Назад</span>
          </v-btn>
        </template>

        <template v-else>
          <v-btn
            class="footer-button grow"
            color="secondary"
            height="80"
            tile
            icon
            x-large
            @click="$store.dispatch('menu/toggle')"
          >
            <v-icon v-show="menu" x-large>{{ icons.mdiCloseThick }}</v-icon>
            <icon-menu v-show="!menu" />
            <span class="footer-button__label dark--text">Меню</span>
          </v-btn>
        </template>

        <v-btn
          v-for="(button, i) in buttons"
          :key="i"
          class="footer-button grow"
          color="secondary"
          height="80"
          tile
          icon
          x-large
          :to="button.to"
          @click.native="$store.dispatch('menu/hide')"
        >
          <v-badge
            v-if="button.text == 'Корзина'"
            color="primary"
            :content="cartCount.toString()"
            overlap
          >
            <v-icon x-large>{{ button.icon }}</v-icon>
          </v-badge>

          <v-icon x-large v-else>{{ button.icon }}</v-icon>

          <span class="footer-button__label dark--text">{{ button.text }}</span>
        </v-btn>
      </div>
    </transition>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import IconMenu from '@/components/icons/IconMenu'
import IconBackArrow from '@/components/icons/IconBackArrow'
import { mapState } from 'vuex'

import {
  mdiSilverware,
  mdiClipboardText,
  mdiAccountCircle,
  mdiCart,
  mdiArrowLeft,
  mdiArrowLeftThick,
  mdiCloseThick,
} from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiArrowLeft,
      mdiArrowLeftThick,
      mdiCloseThick,
    },
  }),

  components: {
    ActionButton,
    IconMenu,
    IconBackArrow,
  },

  computed: {
    buttons() {
      const _buttons = [
        {
          text: 'Акции',
          icon: mdiClipboardText,
          to: { name: 'Sales' },
        },
        {
          text: 'Профиль',
          to: { name: 'Personal' },
          icon: mdiAccountCircle,
        },
      ]

      if (!this.$store.getters['aggregator/isAggregator']) {
        _buttons.unshift({
          text: 'Еда',
          to: { name: 'Catalog' },
          icon: mdiSilverware,
        })
        _buttons.push({
          text: 'Корзина',
          to: { name: 'Cart' },
          icon: mdiCart,
        })
      }

      return _buttons
    },

    cartCount() {
      return this.$store.state.checkout.cartCount
    },

    userIsAuthorized() {
      return this.$store.getters['user/isAuthorized']
    },

    ...mapState(['actionButton']),
    ...mapState('menu', { menu: 'active' }),
  },

  methods: {
    toMenu() {
      if (this.$router.name == 'Menu') {
        this.$router.back()
      } else {
        this.$router.push({ name: 'Menu' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-inner {
  height: 80px;
  height: calc(80px + constant(safe-area-inset-bottom));
  height: calc(80px + env(safe-area-inset-bottom));
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.transition-block {
  position: absolute;
  width: 100%;
}

.footer-button {
  // transition: 0.05s;
  border-radius: $border-radius-root;

  ::v-deep .v-btn__content {
    flex-direction: column;
  }

  &__label {
    margin-top: 2px;
    text-transform: none;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
  }

  &:not(&--back) {
    &.v-btn--active {
      color: var(--v-white-base) !important;
      background: var(--v-primary-base);

      .footer-button__label {
        color: var(--v-white-base) !important;
      }

      ::v-deep path {
        fill: var(--v-white-base) !important;
      }
    }

    /* &:focus,
    &:hover {
      color: var(--v-white-base) !important;
      background: var(--v-primary-base);

      .footer-button__label {
        color: var(--v-white-base) !important;
      }

      ::v-deep path {
        fill: var(--v-white-base) !important;
      }
    } */
  }
}
</style>
