import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/reactive'
import { roundupPrice } from './utils'
import vuetify from './plugins/vuetify'
import api from './plugins/api'
import noPhoto from '@/assets/images/no-photo.svg'
import VueLazyLoad from 'vue-lazyload'
import '@/assets/scss/jw74.scss'
import recaptcha from '@/plugins/recaptcha'
import device from 'vue-device-detector'
import { tracardiInit } from './plugins/tracardi'

Vue.use(device)

Vue.use(VueLazyLoad, {
  preLoad: 1,
  attempt: 1,
  loading: noPhoto,
  error: noPhoto,
  listenEvents: ['scroll'],
})

import VueMask from 'v-mask'
Vue.use(VueMask)

import truncateFilter from '@/filters/truncate'
Vue.filter('truncate', truncateFilter)

import '@/assets/scss/main.scss'
import '@/assets/fonts/stylesheet.css'

Vue.prototype.$api = api
Vue.prototype.$price = { roundupPrice }
// Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const query = to.query || {}

  if (query.utm_source) {
    sessionStorage.setItem('utm_source', query.utm_source)
  }

  if (query.utm_medium) {
    sessionStorage.setItem('utm_medium', query.utm_medium)
  }

  if (query.utm_campaign) {
    sessionStorage.setItem('utm_campaign', query.utm_campaign)
  }

  const isAuthorized = store.getters['user/isAuthorized']

  if (to.meta.auth && !isAuthorized) {
    next({ name: 'Welcome' })
  } else if (to.meta.guest && isAuthorized) {
    next({ name: 'Welcome' })
  } else next()
})

if (
  window.location.hostname === 'qr.vsem-edu-oblako.ru' &&
  !Vue.$device.mobile
) {
  document.querySelector('.preloader').innerHTML =
    '<h3>QR-меню доступно для просмотра только с мобильного устройства</h3>'
} else if (window.browser) {
  onDeviceReady()
} else {
  document.addEventListener('deviceready', onDeviceReady, false)
}

async function onDeviceReady() {
  pullMerchantKey()

  await store.dispatch('appInit')

  Vue.use(recaptcha)

  if (store.getters['aggregator/isAggregator']) {
    store.commit('aggregator/setKey', localStorage.getItem('merchantKey'))
    sessionStorage.setItem('aggregatorKey', localStorage.getItem('merchantKey'))
  } else if (store.getters['aggregator/isAggregatorChild']) {
    store.commit('aggregator/setKey', sessionStorage.getItem('aggregatorKey'))
  }

  const cities = store.getters['app/cities']
  if (cities && Object.keys(cities).length) {
    store.commit('menu/show')
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')

  const params = getQueryParams()
  if (params && params.get('page') === 'orderSuccess' && params.get('id')) {
    router.push({
      name: 'PaymentSuccess',
      query: {
        id: params.get('id'),
      },
    })
    store.commit('menu/hide')
  }

  Vue.prototype.$tracardi = tracardiInit({ router })
}

function pullMerchantKey() {
  const params = getQueryParams()
  const merchantKey =
    params.get('merchantKey') ||
    localStorage.getItem('merchantKey') ||
    window.merchantKey
  localStorage.setItem('merchantKey', merchantKey)
  localStorage.setItem('platform', params.get('platform') || 'express')
}

function getQueryParams() {
  return new URLSearchParams(window.location.search)
}

window.addEventListener('cordovaEvent', async (event) => {
  const { name: eventName, data: eventData } = event.detail

  if (eventName === 'fcmToken' && eventData.value) {
    store.commit('app/setFcmToken', eventData.value)
    // await store.dispatch("app/loadSettings");
    await store.dispatch('checkout/loadCart')
  }

  if (eventName === 'playerId' && eventData.value) {
    const playerId = eventData.value
    store.commit('app/setPlayerId', playerId)
  }

  if (eventName === 'route' && eventData.path) {
    router.push(eventData.path)
  }
})

document.body.onclick = function (event) {
  function handleLink(href) {
    if (window.webkit?.messageHandlers?.cordova_iab?.postMessage) {
      event.preventDefault()
      try {
        window.webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            type: 'open',
            data: {
              url: href,
              target: '_system',
            },
          })
        )
      } catch (error) {
        alert(error)
      }
    }
  }
  const target = event.target.closest('a')
  if (
    target &&
    target.classList.contains('external-link') &&
    document.body.contains(target)
  ) {
    return handleLink(target.getAttribute('href'))
  }
}
