<template>
  <v-btn
    class="grow mx-4 button-shadow px-2"
    :color="color"
    x-large
    :loading="button.loading"
    @click="button.action"
    :disabled="button.disabled"
  >
    <template v-if="button.isGooglePay">
      <img
        class="gpay-icon gpay-icon--dark"
        src="@/assets/images/payment/gpay-icon-black.svg"
        alt=""
      />
      <img
        class="gpay-icon gpay-icon--light"
        src="@/assets/images/payment/gpay-icon-white.svg"
        alt=""
      />
    </template>

    <template v-else-if="button.isApplePay">
      <img
        class="apay-icon apay-icon--dark"
        src="@/assets/images/payment/apay-icon-black.svg"
        alt=""
      />
      <img
        class="apay-icon apay-icon--light"
        src="@/assets/images/payment/apay-icon-white.svg"
        alt=""
      />
    </template>

    <template v-else>
      {{ button.text }}
    </template>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({ button: 'actionButton' }),

    color() {
      if (this.button.isGooglePay || this.button.isApplePay) {
        return this.$vuetify.theme.isDark ? '#fff' : '#000'
      }
      return 'primary'
    },

    /* button() {
      return {
        ...this.$store.state.actionButton,
        action: this.$store.state.actionButton.action || (() => {}),
      }
    }, */
  },
}
</script>

<style lang="scss">
.gpay-icon,
.apay-icon {
  height: 20px;

  .theme--dark &--dark {
    display: none;
  }

  &--light {
    display: none;

    .theme--dark & {
      display: inline;
    }
  }
}
</style>
