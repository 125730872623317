import { mdiWindowClose } from "@mdi/js";
import { EShopSettings } from "@/enums/shop.settings.enum";

/** @var activeDialogRoutes */
const activeDialogRoutes = [
  "Cart",
  "Gift",
  "Checkout",
  "PaymentSuccess",
  "Login",
  "Registration",
  "ForgotPass",
  "Sales",
  "Welcome",
];

/**
 * Make hydration for dialogs;
 *
 * @export
 * @return {*}
 */
export function makeHydrationDialog() {
  return Object.assign({
    data: () => ({
      icons: { mdiWindowClose },
    }),
    computed: {
      isActiveDialogRoute() {
        return !activeDialogRoutes.includes(this.$route.name);
      },
      closedShopSettings() {
        return (
          this.isActiveDialogRoute &&
          this.$store.getters["app/shopSettings"]({
            strategy: EShopSettings.CLOSED,
          })
        );
      },
      servedShopSettings() {
        return (
          this.isActiveDialogRoute &&
          this.$store.getters["app/shopSettings"]({
            strategy: EShopSettings.SERVED,
          })
        );
      },
      feastedShopSettings() {
        return (
          this.isActiveDialogRoute &&
          this.$store.getters["app/shopSettings"]({
            strategy: EShopSettings.FEASTED,
          })
        );
      },
      orderedShopSettings() {
        return (
          this.isActiveDialogRoute &&
          this.$store.getters["app/shopSettings"]({
            strategy: EShopSettings.ORDERED,
          })
        );
      },
    },
  });
}
