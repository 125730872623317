<template>
  <v-sheet>
    <div
      class="order-buttons px-4"
      :class="[selectedOrderType === 'delivery' ? 'fixed' : 'mt-3 mb-10']"
    >
      <OrderTypeButtons />
    </div>

    <DeliveryMap
      v-if="selectedOrderType === 'delivery'"
      @setLoading="loading = $event"
    ></DeliveryMap>

    <div v-show="selectedOrderType === 'pickup'" class="px-4">
      <h2>Пункты самовывоза</h2>

      <v-radio-group v-model="pickupPointModelValue">
        <v-radio
          v-for="(pickupPoint, i) in pickupPoints"
          :key="i"
          :label="pickupPoint.text"
          :value="pickupPoint.value"
        ></v-radio>
      </v-radio-group>
    </div>

    <div v-show="selectedOrderType === 'dinein'" class="px-4">
      <h2>Список заведений</h2>

      <v-radio-group v-model="dineinPointModelValue">
        <v-radio
          v-for="(dineinPoint, i) in dineinPoints"
          :key="i"
          :label="dineinPoint.text"
          :value="dineinPoint.value"
        ></v-radio>
      </v-radio-group>
    </div>

    <div class="submit-btn px-4">
      <v-btn
        class="footer-button"
        color="secondary"
        height="80"
        tile
        icon
        x-large
        @click="close"
      >
        <v-icon x-large>{{ icons.mdiCloseThick }}</v-icon>
        <span class="footer-button__label dark--text">Закрыть</span>
      </v-btn>

      <v-btn
        class="grow mx-4 button-shadow px-2"
        color="primary"
        x-large
        :disabled="btnDisabled"
        @click="submit"
      >
        Подтвердить адрес
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { mdiCloseThick, mdiArrowLeftThick } from '@mdi/js'
import OrderTypeButtons from '@/components/OrderTypeButtons'
import DeliveryMap from '@/components/address/DeliveryMap'

export default {
  emits: ['modalClosed'],

  components: {
    OrderTypeButtons,
    DeliveryMap,
  },

  data: () => ({
    icons: {
      mdiCloseThick,
      mdiArrowLeftThick,
    },

    loading: false,
  }),

  computed: {
    ...mapState('checkout', ['fields', 'dineinPoint']),

    ...mapGetters('checkout', [
      'pickupPoints',
      'dineinPoints',
      'selectedOrderType',
    ]),

    pickupPointModelValue: {
      get() {
        return this.fields.pickupPoint
      },
      set(value) {
        this.setPickupPoint(value)
      },
    },

    dineinPointModelValue: {
      get() {
        return this.dineinPoint
      },
      set(value) {
        this.setDineInPoint(value)
      },
    },

    btnDisabled() {
      if (
        this.selectedOrderType === 'delivery' &&
        (!this.$store.state.app.settings.merchant_yandex_api_key ||
          this.loading)
      ) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    ...mapMutations('checkout', [
      'setPickupPoint',
      'setDineInPoint',
      'setField',
    ]),

    ...mapActions('checkout', ['selectComplete']),

    close() {
      this.$emit('modalClosed')
      this.$store.state.address.active = false
    },

    async submit() {
      this.$store.commit('overlay/update', { active: true })
      try {
        await this.selectComplete()
      } catch (error) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: error.message,
        })
      } finally {
        this.$store.commit('overlay/update', { active: false })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-sheet {
  height: 100%;
  border-radius: 0;
  overflow-y: scroll;
  padding-bottom: 120px;
}

h2 {
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px;
}

.order-buttons {
  width: 100%;
}

.fixed {
  z-index: 10;
  position: fixed;
  top: 12px;
}
.submit-btn {
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  background: #ffff;
  border-top-left-radius: $border-radius-root;
  border-top-right-radius: $border-radius-root;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  .theme--dark & {
    background: #333333;
  }
}

.footer-button {
  // transition: 0.05s;
  border-radius: $border-radius-root;

  ::v-deep .v-btn__content {
    flex-direction: column;
  }

  &__label {
    margin-top: 2px;
    text-transform: none;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
  }

  &:not(&--back) {
    &.v-btn--active {
      color: var(--v-white-base) !important;
      background: var(--v-primary-base);

      .footer-button__label {
        color: var(--v-white-base) !important;
      }

      ::v-deep path {
        fill: var(--v-white-base) !important;
      }
    }

    /* &:focus,
    &:hover {
      color: var(--v-white-base) !important;
      background: var(--v-primary-base);

      .footer-button__label {
        color: var(--v-white-base) !important;
      }

      ::v-deep path {
        fill: var(--v-white-base) !important;
      }
    } */
  }
}
</style>
