<template>
  <v-app class="app">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInDown animate__faster"
      leave-active-class="animate__animated animate__slideOutUp  animate__faster"
    >
      <v-app-bar
        id="app-bar"
        app
        color="white"
        v-show="!menu && $route.name !== 'Product'"
      >
        <v-btn
          v-if="
            $store.getters['aggregator/isAggregatorChild'] &&
            $route.name === 'Home'
          "
          icon
          color="dark"
          class="mr-3"
          @click="$store.dispatch('aggregator/backToHome')"
        >
          <v-icon color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
        </v-btn>

        <v-btn
          v-else
          :style="{ opacity: $route.name === 'Home' ? 0 : 1 }"
          :disabled="$route.name === 'Home'"
          icon
          color="dark"
          class="mr-3"
          @click="$router.back()"
        >
          <!-- <v-icon>{{ icons.mdiMagnify }}</v-icon> -->
          <v-icon color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
        </v-btn>

        <!-- <router-link :to="{name: 'Home'}" class="app-title dark--text">Всем еды Express</router-link> -->

        <div class="app-title dark--text" @click="appTitleClicked">
          {{
            $store.getters['checkout/addressName'] || $store.getters['app/name']
          }}
        </div>

        <v-btn
          v-if="!$store.getters['app/isQrMenu']"
          icon
          class="ml-auto"
          color="dark"
          :to="{ name: 'Notifications' }"
        >
          <v-icon>{{ icons.mdiBell }}</v-icon>
        </v-btn>
      </v-app-bar>
    </transition>

    <v-main>
      <v-overlay :value="overlay.active">
        <v-progress-circular indeterminate color="primary" size="64" />
      </v-overlay>
      <router-view v-show="!menu" />
      <the-menu v-show="menu" />
    </v-main>

    <v-footer
      id="app-footer"
      height="auto"
      padless
      app
      color="white"
      class="align-start"
    >
      <footer-buttons />
    </v-footer>

    <the-snackbar />

    <gift-button v-if="giftButtonIsActive" />

    <line-gift-new v-if="!$store.getters['app/isQrMenu']" />

    <is-closed-dialog v-if="closedShopSettings && closedShopSettings.enabled" />
    <is-feasted-dialog
      v-if="feastedShopSettings && feastedShopSettings.enabled"
    />
    <is-ordered-dialog
      v-if="orderedShopSettings && orderedShopSettings.enabled"
    />
    <is-served-dialog
      v-if="servedShopSettings && !servedShopSettings.disabled"
    />
    <template v-cloak v-if="isWheelAppShop && !$store.getters['app/isQrMenu']">
      <div id="wheel"></div>
    </template>

    <template>
      <v-dialog
        v-model="addressActive"
        fullscreen
        persistent
        no-click-animation
        transition="dialog-bottom-transition"
      >
        <AddressSelect @modalClosed="onClosed" />
      </v-dialog>
    </template>
  </v-app>
</template>

<script>
import LineGiftNew from '@/components/LineGiftNew'
import { makeHydrationDialog } from '@/mixins/dialogMixin'
import { mdiBell, mdiMagnify, mdiKeyboardBackspace } from '@mdi/js'
import FooterButtons from '@/components/FooterButtons'
import TheSnackbar from '@/components/TheSnackbar'
import TheMenu from '@/components/TheMenu'
import { mapState, mapMutations } from 'vuex'
import GiftButton from '@/components/GiftButton'
import IsClosedDialog from '@/components/dialogs/IsClosedDialog'
import IsFeastedDialog from '@/components/dialogs/IsFeastedDialog'
import IsOrderedDialog from '@/components/dialogs/IsOrderedDialog'
import IsServedDialog from '@/components/dialogs/IsServedDialog'
import AddressSelect from '@/components/address/AddressSelect'
import WheelMixin from '@/plugins/wheel'

export default {
  mixins: [makeHydrationDialog(), WheelMixin],

  data: () => ({
    icons: {
      mdiBell,
      mdiMagnify,
      mdiKeyboardBackspace,
    },

    initOrderType: null,
  }),

  computed: {
    ...mapState(['overlay']),
    ...mapState('menu', { menu: 'active' }),

    isWheelAppShop() {
      const { availability, storeHash } = this.appShop || {}
      return (
        availability && storeHash && !this.mainRoutes.includes(this.$route.name)
      )
    },

    theme() {
      return this.$store.state.app.settings?.theme_option
    },

    giftButtonIsActive() {
      if (!this.$store.state.gift?.data?.items?.length) {
        return
      }
      return !this.mainRoutes.includes(this.$route.name)
    },

    darkTheme() {
      return (
        this.theme?.config?.express_color === 'dark' ||
        this.theme?.background === 'dark'
      )
    },

    themeColor() {
      return this.theme?.config?.express_hex || this.theme?.hex
    },

    addressActive: {
      get() {
        if (this.$store.getters['app/isQrMenu']) {
          return false
        } else {
          return this.$store.state.address.active
        }
      },
      set(value) {
        this.SET_ACTIVE(value)
      },
    },

    orderType() {
      return this.$store.getters['checkout/selectedOrderType']
    },
  },

  watch: {
    addressActive(value) {
      if (value) {
        this.initOrderType = this.orderType
      }
    },
  },

  components: {
    FooterButtons,
    TheSnackbar,
    TheMenu,
    GiftButton,
    LineGiftNew,
    AddressSelect,
    IsClosedDialog,
    IsFeastedDialog,
    IsOrderedDialog,
    IsServedDialog,
  },

  created() {
    if (this.darkTheme) {
      this.$vuetify.theme.dark = true
    }

    if (this.themeColor) {
      this.$vuetify.theme.themes.light.primary = this.themeColor
      this.$vuetify.theme.themes.dark.primary = this.themeColor
    }

    if (
      !this.$store.getters['aggregator/isAggregator'] &&
      this.$store.getters['app/addressModalAutoOpen'] &&
      !this.$store.getters['app/isQrMenu']
    ) {
      this.SET_ACTIVE(true)
    }

    this.initOrderType = this.orderType
  },

  methods: {
    ...mapMutations('address', ['SET_ACTIVE']),

    appTitleClicked() {
      if (this.$store.getters['checkout/addressName']) {
        this.SET_ACTIVE(true)
      }
    },

    onClosed() {
      this.$store.commit('checkout/setOrderType', this.initOrderType)
    },
  },
}
</script>

<style lang="scss" scoped>
#app-bar {
  border-bottom-right-radius: $border-radius-root;
  border-bottom-left-radius: $border-radius-root;
}

#app-footer {
  border-top-left-radius: $border-radius-root;
  border-top-right-radius: $border-radius-root;
}

#app-bar,
#app-footer {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
}

.app-title {
  font-size: 16px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
}
</style>
