<template>
  <div class="error">
    <div class="error-container">
      <div class="error-code">500</div>
      <div class="error-msg">
        {{ errors.msg }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    errors() {
      return this.$store.getters["app/errors"];
    },
  }
}
</script>

<style scoped>
.error {
  min-height: 100vh;
  text-align: center;
  line-height: 1.25;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.error-code {
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: 600;
  --text-opacity: 1;
  color: #434b51;
  color: rgba(67, 75, 81, var(--text-opacity));
}

.error-msg {
  margin-bottom: 2rem;
  font-size: 1rem;
}
</style>