<template>
  <v-btn
    :href="`tel:${phone.replace(/[^\d+]+/g, '')}`"
    class="external-link"
    color="primary"
    x-large
    depressed
    block
  >
    <v-icon large>{{ icons.mdiPhone }}</v-icon>
    <v-spacer />
    <div>{{ phone }}</div>
    <v-spacer />
  </v-btn>
</template>

<script>
import { mdiPhone } from "@mdi/js";

export default {
  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    icons: {
      mdiPhone,
    },
  }),
};
</script>
