const routes = [
  {
    path: "/catalog",
    name: "Catalog",
    component: () => import("@/views/Catalog.vue"),
  },
  {
    path: "/catalog/:section",
    name: "Section",
    component: () => import("@/views/Section.vue"),
  },
  {
    // path: "/catalog/:section/:product",
    path: "/catalog/product/:product",
    name: "Product",
    component: () => import("@/views/Product.vue"),
  },
];

export default routes;
